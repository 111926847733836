module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{"sh":"bash"},"showLineNumbers":false,"noInlineHighlight":false,"languageExtensions":[{"language":"superscript","extend":"javascript","definition":{"superscript_types":{}},"insertBefore":{"function":{"superscript_keywords":{}}}}],"prompt":{"user":"lei","host":"localhost","global":false},"escapeEntities":{}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1024}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"public","ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff"]}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lei Shi","short_name":"leishi","start_url":"/","background_color":"#ffffff","theme_color":"#3182ce","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"afc14ed1c4d2e6eb75f7ebf380f20d71"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://matomo.leishi.io","siteUrl":"https://leishi.io"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
